$(document).ready(function() {

    if (inIframe()) {
        window.top.location.href = "/";
    }

    // Scaling magic

    var minHeight = 768;
    var minWidth = 1366;
    var $sizable = $("#app-container");

    $(window).resize(function(e) {

        //return;

        //console.log(window.innerWidth);
        //console.log(window.innerHeight);

        var scale = 1;

        var margin = 100;
        var windowWidth = window.innerWidth - margin;
        var windowHeight = window.innerHeight - margin;

        if (windowWidth < minWidth || windowHeight < minHeight) {
            scale = Math.min(
                windowWidth / minWidth,
                windowHeight / minHeight
            );
        }
        $sizable.css({
            transform: "scale(" + scale + ")"
        });

    });
    $(window).trigger('resize');

});




$(window).bind("load", function () {
    $(window).trigger('resize');
});



function inIframe () {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
}
